<template>
    <div>
        <div class="serviceDataCreation">
            <el-tabs v-model="activeName" type="card" class="education-content tabs-full">
                <el-tab-pane label="客服管理" name="serviceAdminName">
                    <serviceAdmin />
                </el-tab-pane>
                <el-tab-pane label="客服商品管理" name="serviceGoodsAdminName">
                    <serviceCmmodityAdd />
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import serviceAdmin from './../../components/admin/shopServiceAdmin/serviceAdmin.vue';
    import serviceCmmodityAdd from './../../components/admin/shopServiceAdmin/serviceCmmodityAdd.vue';
    export default {
        name: "serviceCmmodityCreation",
        components: {
            serviceAdmin,
            serviceCmmodityAdd
        },
        data(){
            return {
                activeName:'serviceGoodsAdminName',
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-step__description{
        padding-top: 20px;
    }
    /deep/.el-step__head.is-finish{
        /*background: #1122D8;*/
        /*color: #ffffff;*/
        /*border-color: #1122D8;*/
    }
    /deep/.el-step__icon{
        width: 48px;
        height: 48px;
        font-size: 24px;
    }
    /deep/.el-step__line{
        margin-top: 12px;
    }

</style>