<template>
    <div class="serviceAdmin">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item style="font-weight: bold;">店铺客服管理</el-breadcrumb-item>
            <el-breadcrumb-item>客服管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="serviceAdmin-header">
            <IndustrySelection @selectListChange="selectListChange" :selectList="selectList" text="行业分类："></IndustrySelection>
            <div>
                <!-- <el-button class="newClientBtn" @click="exportServiceData">导出客服数据</el-button>
                <el-button class="newClientBtn" onclick="fileInput.click()">导入客服数据</el-button> -->
                <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>
                <input
                        ref="modelInput"
                        type="file"
                        id="fileInput"
                        @change="uploadServiceData($event)"
                        style="display:none"
                        accept=".sql"
                />
                <router-link :to="{ name: 'createStepOne'}"><el-button class="newClientBtn">创建客服数据</el-button></router-link>
            </div>
        </div>
        <div class="serviceAdmin-table">
            <el-table  ref="multipleTable" :data="serviceData" border @selection-change="handleSelectionChange" style="margin-top:20px;width: 100%;flex: 1;border: #EEEEEE 1px solid;height: calc(100vh - 320px);overflow-y: auto" :cell-style="{color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: 500, color: '#14141C', background: '#F3F4FA',height: '60px'}">
                <el-table-column v-if="serviceData.length" type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="kf_name" label="客服数据名称" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                <el-table-column prop="category_name" label="行业分类" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #67C23A" @click="allotTemplate(scope.row.id)">分配商品</el-button>
                        <el-button type="text" style="color: #1122D8" @click="copyBtn(scope.row.id)">复制</el-button>
                        <el-button type="text" style="color: #1122D8" @click="editService(scope.row)">编辑</el-button>
                        <el-button type="text" style="color: #FF0000" @click="detaleService(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
        <el-dialog
                title="分配模块"
                :visible.sync="dialogVisible"
                width="50%">
            <el-select v-model="selectVal" clearable placeholder="请选择" size="small" @change="getAllotTemplate()" style="margin-bottom: 24px">
                <el-option v-for="item in selectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-checkbox-group v-model="checkedGoods" @change="handleCheckedChange">
                <el-checkbox v-for="(item,index) in templateList" :label="item.goods_id" :key="index">
                    <el-tooltip class="box-item" :content="item.goods_name" placement="top">
                        <span style="display: inline-block; width: 120px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ item.goods_name }}</span>
                    </el-tooltip>
                </el-checkbox>
            </el-checkbox-group>
            <span slot="footer" class="dialog-footer">
    <el-button @click="closeAllot">取 消</el-button>
    <el-button type="primary" @click="confirmAllot">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import { copyKeFuAPI, sendKeFuAPI } from "../../../utils/apis"
    import IndustrySelection from "../../admin/IndustrySelection";

    export default {
        name: "serviceAdmin",
        components: {
            IndustrySelection
        },
        data(){
            return {
                checkedGoods:[],
                serviceData:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                dialogVisible:false,
                templateList:[],
                templateId:'',
                selectServiceData:[],
                selectList: [],
                selectVal: void 0,
                selectCategory: void 0,
            }
        },
        created() {
            this.getService();
            // this.getAllotTemplate();
            this.getCategoryConfig();
        },
        activated() {
            this.getService(this.selectCategory);
        },
        methods: {
            async sendBtn(){
                let id =this.$refs.multipleTable.selection.map((item) => item.id);
                if(id && id.length > 0){
                  const result = await this.$common.sendData(sendKeFuAPI, { ids: id });
                  result && this.getService(this.selectCategory);
                }else{
                  this.$message.error("请勾选需要发送的数据！")
                }
            },
            selectListChange(id) {
                this.selectCategory = id;
                this.getService(id);
            },
            // 获取行业分类
            getCategoryConfig() {
                this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
                    if (res.code === 200) {
                        this.selectList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            },
            handleCheckedChange(val){
                // console.log(87,this.checkedGoods)
            },
            closeAllot(){
                // this.checkedGoods = [];
                this.dialogVisible = false;
                this.selectVal = void 0;
            },
            confirmAllot(){
                if(this.checkedGoods.length === 0){
                    this.$http.axiosPost(this.$api.fpGoods, {id:this.templateId,goods_ids:''},res=>{
                        if(res.code === 200){
                            this.$message.success('商品分配成功！');
                            this.dialogVisible = false
                            this.selectVal = void 0;
                        } else {
                            this.$message.warning(res.msg);
                            this.dialogVisible = false
                        }
                    })
                    return;
                }
                let str = '';
                if(this.checkedGoods.length > 1){
                    str = this.checkedGoods[0];
                    let separator = '#';
                    for(let i = 1; i < this.checkedGoods.length; i++){
                        str += separator + this.checkedGoods[i];
                    }
                } else if(this.checkedGoods.length === 1){
                    str += this.checkedGoods[0];
                }
                let param = {
                    id:this.templateId,
                    goods_ids:str
                }
                this.$http.axiosPost(this.$api.fpGoods, param, res=>{
                    if(res.code === 200){
                        this.$message.success('商品分配成功！');
                        this.dialogVisible = false
                        this.selectVal = void 0;
                    } else {
                        this.$message.warning(res.msg);
                        this.dialogVisible = false
                    }
                })
            },
            getService(id){
                let param = {
                    page:this.adminPages.currentPageNum,
                    limit:this.adminPages.eachPageNum,
                    category_id: id ? id : ''
                };
                this.$http.axiosGetBy(this.$api.showKfList, param, (res)=>{
                    if(res.code === 200){
                        this.serviceData = res.data.data;
                        this.adminPages.total = res.data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getAllotTemplate(){
                let param = {
                    type:'all',
                    category_id: this.selectVal,
                }
                this.$http.axiosGetBy(this.$api.showGoods, param, res=>{
                    if(res.code === 200){
                        this.templateList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            allotTemplate(id){
                this.getAllotTemplate();
                this.templateId = id;
                this.checkedGoods = [];
                this.$http.axiosGetBy(this.$api.findByKfDate, {id:id}, res=>{
                    if(res.code === 200){
                        if(res.data.goods_data.length){
                            let goodsData = res.data.goods_data;
                            goodsData.map(item=>{
                                this.checkedGoods.push(item.goods_id)
                            })
                        }
                        this.dialogVisible = true;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            editService(row){
                this.$http.axiosGetBy(this.$api.findByKfDate, {id:row.id}, res=>{
                    if(res.code === 200){
                        let editData = JSON.stringify(res.data);
                        this.$router.push({
                            path:'/admin/storecustomer/createstepone',
                            query:{
                                data:editData
                            }
                        })
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            detaleService(row){
                this.$confirm('此操作将永久删除该文件, 是否继续?','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type:'warning'
                }).then(()=>{
                    this.$http.axiosGetBy(this.$api.delKfData, {id:row.id},(res)=>{
                        if(res.code === 200){
                            this.$message({
                                type:'success',
                                message:'删除成功！',
                                duration:1000,
                                onClose:()=>{
                                    this.getService(this.selectCategory);
                                }
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })

            },
            handleSelectionChange(val){
              this.selectServiceData = val;
            },
            exportServiceData(){
                let str = '';
                if(this.selectServiceData.length === 0){
                    this.$message.warning('请先选择要导出的客服数据')
                    return
                } else if(this.selectServiceData.length === 1){
                    str += this.selectServiceData[0].id;
                } else {
                    str = this.selectServiceData[0].id
                    let separator = '#';
                    for(let i = 1; i<this.selectServiceData.length; i++){
                        str += separator + this.selectServiceData[i].id;
                    }
                }
                let param = {
                    kefu_ids:str
                }
                this.$http.axiosPost(this.$api.kefuToSql, param, res=>{
                    window.location.href = res;
                    // if(res.code === 200){
                    //     this.$message.success('导出成功!');
                    //     window.location.href = res
                    // } else {
                    //     this.$message.warning(res.msg)
                    // }
                })
            },
            uploadServiceData(event){
              let modelName = event.target.files[0].name;
              let limiteFileType = ["sql"];
              if(!limiteFileType.includes(modelName.split(".")[modelName.split(".").length - 1].toLowerCase())){
                  this.$message.warning("文件格式错误！");
                  return;
              }
              let sqlFile = event.target.files[0];
              let formData = new FormData();
              formData.append('kefu_sql', sqlFile);
              this.$http.axiosPost(this.$api.importKefuSql, formData, res=>{
                  if(res.code === 200){
                      this.$message.success('导入成功！');
                      this.getService(this.selectCategory)
                  } else {
                      this.$message.warning(res.msg)
                  }
              })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getService(this.selectCategory)
            },
            async copyBtn(id) {
                    const valid = await this.$common.copyTemplatePack(copyKeFuAPI, { id });
                    valid && this.getService(this.selectCategory);
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-breadcrumb__item .el-breadcrumb__inner{
        color: #303133;
    }
    .serviceAdmin-table{
        /deep/th{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
        /deep/td{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
    }
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-checkbox-group{
        /*display: flex;*/
        /*justify-content: space-between;*/
        /*flex-wrap: wrap;*/
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .serviceAdmin{
        /*height: 100%;*/
        height:100%;
        padding: 30px;
        .serviceAdmin-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            line-height: 40px;
            .el-breadcrumb {
                line-height: 40px;
            }
            div{
                display: flex;
                justify-content: start;
                .newClientBtn{
                    width: 120px;
                    color: #ffffff;
                    background: #1122D8;
                    border-radius:4px;
                    border: none;
                    margin-left: 10px;
                }
            }
        }
    }
</style>